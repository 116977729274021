import React from "react"
import { graphql } from 'gatsby'
import withI18next from "../components/withI18next"
import Layout from "../components/Layout"
import BookingLine from "../components/BookingLine"
import Breadcrumb from "../components/Breadcrumb"
import KidParty from "../components/KidParty"

const KidPartyPage = ({ pageContext: { localeData }, data }) => {

    const {frontmatter: fields} = data.page
    const {childImageSharp: bgImage} = data.bgImage

    return (
      <Layout localeData={localeData} title={fields.title} keywords={fields.keywords} description={fields.description}>
          <BookingLine localeData={localeData}/>
          <Breadcrumb slug={fields.slug} title={fields.title} bgImage={bgImage} textShadow={true} bgOffset="-200"/>
          <KidParty data={fields}  content={data.page.html} />
      </Layout>
    );
};
export default withI18next()(KidPartyPage);

export const query = graphql`
  query($id: String!) {
    page: markdownRemark(id: { eq: $id })
    {
      html
      frontmatter {
        slug
        title
        pageTitle
        pageSubtitle
        date
        contentDescription
        keywords
        description
        questionTitle
        questionDescription
        questionPhone
        questionEmail
        blockquote
      }
    }
    bgImage: file(relativePath: { eq: "gatherings/bg-kid_party.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 1500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    
  }`